import React, {useState, useEffect, useContext, useRef} from 'react';
import { useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext';
import {ValidationForm} from '../services/validationForm.services';
import './FormPreview.scss'
import { SaveOnePreview , UpdatePreview } from '../services/preview.services';
import logoxaxis from "./images/logoxaxis.png"
import creativestudio from "./images/creativestudio.png"
import nexuslogo from "./images/nexuslogo.svg"
import AlertSnackbar from './Partials/AlertSnackbar';
import { loadDefaultPreview , setPreviewInLocalStorage , clearPreviewInLocalStorage , sortDataAlphabetically } from '../utils/common'; 
import { DEFAULT_SIZES , DEFAULT_PREVIEW , COUNTRIES } from '../utils/constants'; 
import ModeIcon from '@mui/icons-material/Mode';

const FormPreview = ({setSizes, setCheckboxs, checkbox , mode = 'create' , dataPreview , sizes}) => {

   // const Token = useContext(AuthContext)

    const containerSize = useRef(null)
    const labelAddSize = useRef(null)

    const [errors, setErrors] = useState({})
    const [isButtonDisable, setIsButtonDisable] = useState(false); 
    const [popupURL, setPopupURL] = useState(null)

    const [preview, setPreview] = useState(DEFAULT_PREVIEW)

    const navigate = useNavigate()

    sortDataAlphabetically(COUNTRIES)

    const dateYear = new Date();
    const anio = dateYear.getFullYear();


    const [openAlert , setOpenAlert] = useState({
        message: '',
        withProgress: false,
        open: false,
        hideDuration: null
    })

    useEffect(()=>{
        if(openAlert.autoClose){
            setTimeout(()=>{
                setOpenAlert({...openAlert , open: false})
            } , 2500)
        }
    },[openAlert.autoClose])

    useEffect(()=>{
        if(mode === 'create'){
            const defaultPreview = loadDefaultPreview()
            setPreview(defaultPreview)
            setSizes([...defaultPreview.sizes])
        }else{
            if(mode !== 'create' && Object.keys(dataPreview).length > 0){
               setPreview(dataPreview)
               setSizes([...dataPreview.sizes])
            }else{
               setPreview(DEFAULT_PREVIEW);
            }
        }
    } , [])

    const defaultSizes = DEFAULT_SIZES;

    
    const onChangePreview = (e) => {
        
        if(e.target.name === 'banner_text' && e.target.value.length > 13){
            setErrors({...errors , banner_text: 'maximum 13 characters'})
            return;
        }else{
            setErrors({...errors , banner_text: ''})
        }
        setPreview({...preview, [e.target.name]: e.target.value})
        setPreviewInLocalStorage(preview)
    }

    const onSubmit = (e) => {
       e.preventDefault()
       const {width, height, ...rest  } = preview
       let errorsForm = ValidationForm(preview)
       setErrors({...errorsForm})
       if (Object.keys(errorsForm).length === 0) {
         mode === 'create' ? saveOrUpdatePreview() : updatePreview()
       }
    }

    const saveOrUpdatePreview = () => {
        setOpenAlert({ open: true, message: 'Creating creative...', withProgress: true, autoClose: false})
        setIsButtonDisable(true)
        SaveOnePreview(preview)
        .then(data => {
            const {id} = data
            const url = `${process.env.REACT_APP_PREVIEW_IFRAME}/show-preview/${id}`;
            navigator.clipboard.writeText(url)
            id ?  setPopupURL(url) : setPopupURL(null)
            setOpenAlert({ open: true, message: 'Creative created and copied to the clipboard!', withProgress: false, autoClose: true})
            clearPreviewInLocalStorage()
            setSizes([])
            clearForm()
        })
    }

    const updatePreview = () => {
        setOpenAlert({ open: true, message: 'Updating creative...', withProgress: true, autoClose: false})
        setIsButtonDisable(true)
        console.log("updated..." , preview)
        UpdatePreview(preview, dataPreview.id).then((data) => {
            setOpenAlert({ open: true, message: 'Creative updated and copied to the clipboard!', withProgress: false, autoClose: true})
            clearPreviewInLocalStorage()
            navigate(`/show-preview/${ dataPreview.id }`)
        });
    }

    const clearForm = () =>{
        setTimeout(()=>{
            setPreview(loadDefaultPreview())
            setIsButtonDisable(false)
            setPopupURL(null)
        } , 5000)
    }

    const handleOnClick = (e) => {
        e.preventDefault()
        const newSize = { width: preview.width , height: preview.height, banner_text: preview.banner_text, url : ""}
        !checkbox ? setPreview({...preview, sizes: [...preview.sizes , newSize]})   :
        setPreview({...preview, sizes: [{
            height: "100%",
            url: "",
            width: "100%",
            banner_text: ""
          }]}) 
    } 
    const onChangeURLcreative = (index , event) => {
        let newSizes = [ ...preview.sizes ]
        newSizes[ index ]['url'] = event.target.value
        setPreview({...preview, sizes: [...newSizes]})
    }
    const handleDelete = (e, index) => {
        e.preventDefault();
        let newSizes = preview.sizes.filter(  ( size , indexS) => index !== indexS )
        setPreview({...preview, sizes: [...newSizes]})
        setSizes([...newSizes])
    }
    const handleBlur = () => {
        setSizes([...preview.sizes])
    }

    const handleBannerText = ( e , index ) => {
        if(e.target.value.length > 13){
            return;
        }
        let newSizes = sizes.map((size , indexS) => {
            if(index === indexS){
                return {
                    ...size,
                    banner_text: e.target.value
                }
            }
            return size
        })
        setPreview({...preview, sizes: [...newSizes]})
        setSizes([...newSizes])
    }

    const handleChangeSize = (e , index , type) => {
        if(isNaN(Number(e.target.value))){
            return;
        }
        let newSizes = sizes.map((size , indexS) => {
            if(index === indexS){
                return {
                    ...size,
                    [type]: e.target.value
                }
            }
            return size
        })
        setPreview({...preview, sizes: [...newSizes]})
        setSizes([...newSizes])
    }

    const doubled = preview.sizes.map((preview, index) => {
        return (
            <div key={index} className="preview__form-newsize" >
                <button type='button' onClick={ (e)=>  { handleDelete(e, index) } } className='delete-size'>+</button>
                {
                    !checkbox ? <div>
                        <input  value={preview.width} type="text" onChange={(e)=>handleChangeSize(e,index ,'width')} className="input size style" />x<input value={preview.height} onChange={(e)=>handleChangeSize(e,index ,'height')} type="text" className="input size style" />
                    </div> 
                    
                    : <div style={{fontSize: 10}}>Full Size Preview</div>
                }
                
                <input type="text" onBlur={handleBlur} placeholder='URL' name='url' value={preview.url} onChange={(event) => onChangeURLcreative(index , event)}></input>
              
                <input type="text" className="bannertext input style" onChange={(e)=>handleBannerText(e,index)} value={preview.banner_text} />
                  
                {
                    errors.sizes ? <span className='errors'>{errors.sizes}</span> : null
                }
            </div>
        )
    });


    const handleFullSize = (e) => {

        if(!checkbox) {
            setPreview({...preview, sizes: [{ height: "100",
            url: " ",
            width: "100",
            full_size: "1"
            }]})
            setSizes([])
              setCheckboxs(true)
        }else {
            setPreview({...preview, sizes: [...defaultSizes]})
            setSizes([])
            setCheckboxs(false)
        }
        
 
    }
    const addSizeAccordion = () => {
         containerSize.current.classList.toggle("open")
         labelAddSize.current.classList.toggle("open")
    }
    const copyBoard = (e) => {
        let copyURL = e.target
        copyURL.select()
        navigator.clipboard.writeText(copyURL.value)
    }

    return (
        <>
            <div className='preview__form'>
                <div className='preview__form-bg_waves'></div>
                <div>
                    <div className='preview__form-logo'>
                        <img src={nexuslogo} alt="Nexus Creative Studios" />
                    </div>
                    <h1>{ mode === 'create' ? 'Preview Builder' : 'Edit preview' }</h1>
                    <form onSubmit={onSubmit}>
                        <div className="field">
                            {
                                errors.advertiser ? <span className='errors'>{errors.advertiser}</span> : null
                            }
                            <input onChange={onChangePreview} value={ preview.advertiser }  type="text"  name="advertiser" id="advertiser" placeholder="Advertiser name"/>
                            <label htmlFor="advertiser">Advertiser</label>
                        </div>
                        <div className='field'>
                            {
                                errors.market ? <span className='errors'>{errors.market}</span> : null
                            }
                            <select name='market' value={preview.market} onChange={onChangePreview}>
                                <option>Select country</option>
                                {
                                    COUNTRIES.map((country) => <option key={country} value={country}>{country}</option>  )
                                }
                            </select>
                            <label htmlFor='market'>Market</label>
                        </div>
                        <div className="field">
                            {
                                errors.campaign ? <span className='errors'>{errors.campaign}</span> : null
                            }
                            <input onChange={onChangePreview} value={preview.campaign}  type="text" name="campaign" id="advertiser" placeholder="Campaign name"/>
                            <label htmlFor="Campaign">Campaign</label>
                        </div>
                        <div className='field'>
                            {
                                errors.format ? <span className='errors'>{errors.format}</span> : null
                            }
                            <select name='format' value={preview.format}  onChange={onChangePreview}>
                                <option>Select format</option>
                                <option value="3D Showroom">3D Showroom</option>
                                <option value="Standard Banners">Standard Banners</option>
                                <option value="Playable Ads">Playable Ads</option>
                                <option value="Videoboard">Videoboard</option>
                                <option value="Shoppable">Shoppable</option>
                                <option value="Interstitial">Interstitial</option>
                                <option value="Lookbook">Lookbook</option>
                                <option value="Advanced TV">Advanced TV</option>
                                <option value="Interscroller">Interscroller</option>
                                <option value="Reactive">Reactive</option>
                                <option value="Click to call">Click to call</option>
                                <option value="Click to calendar">Click to calendar</option>
                                <option value="SONA">SONA</option>
                                <option value="Tap to Map">Tap to Map</option>
                                <option value="Conversational Ads">Conversational Ads</option>
                                <option value="Prism">Prism</option>
                                <option value="Expandable">Expandable</option>
                                <option value="Skin video">Skin video</option>
                                <option value="DCO">DCO</option>
                                <option value="DOOH">DOOH</option>
                                <option value="Billboard">Billboard</option>
                                <option value="Filmstrip">Filmstrip</option>
                                <option value="XCS Format">XCS Format</option>
                            </select>
                            <label htmlFor='format'>Format</label>
                        </div>
                        <div className='field'>
                            {
                                errors.region ? <span className='errors'>{errors.region}</span> : null
                            }
                            <div className='radio-group'>
                                <div>
                                    <input onChange={onChangePreview}  type="radio" id='latam'  value="latam" name='region' checked={ preview.region === 'latam' } />
                                    <label htmlFor="latam">LATAM</label>
                                </div>           
                                <div>
                                    <input onChange={onChangePreview}  type="radio" id='emea' value="emea" name='region' checked={ preview.region === 'emea'} />
                                    <label htmlFor="emea">EMEA</label>
                                </div>
                                <div>
                                    <input onChange={onChangePreview}  type="radio" id='apac' value="apac" name='region' checked={ preview.region === 'apac' } />
                                    <label htmlFor="apac">APAC</label>
                                </div>
                                <div>
                                    <input onChange={onChangePreview}  type="radio" id='ntam' value="ntam" name='region' checked={ preview.region === 'ntam'  } />
                                    <label htmlFor="ntam">NTAM</label>
                                </div>
                                <div>
                                    <input onChange={onChangePreview}  type="radio" id='global' value="global" name='region' checked={ preview.region === 'global' } />
                                    <label htmlFor="global">GLOBAL</label>
                                </div>
                            </div>
                            <label>Region</label>
                        </div>
                        <div className='field preview__form-addsize'>
                            <span onClick={addSizeAccordion} className="" ref={labelAddSize}>Add new size</span>
                            <div className='container-addsize' ref={containerSize}>
                                <div className='optionfullsize'>
                                    <label htmlFor='fullsize' className='switch'>
                                        <input onChange={(e) => handleFullSize(e)} type="checkbox" id='fullsize' name='fullsize' />
                                        <span className="slider round"></span>
                                        <span className='label'>Full size preview</span>
                                    </label>
                                </div>
                                {
                                    !checkbox  ? 
                                    <div className='fieldsad'>
                                        <div className='sizead'>
                                            <div className='field-size'>
                                                <label htmlFor='width'>Width</label>
                                                <input type="number"  onChange={onChangePreview} name='width' placeholder=''></input>
                                            </div>
                                            <div className='field-size'>
                                                <label htmlFor='height'>Height</label>
                                                <input type="number" onChange={onChangePreview} name='height' placeholder=''></input>
                                            </div>
                                        </div>
                                        <div className='bannerad'>
                                            <div className='field-banner'>
                                                {
                                                    errors.banner_text ? <span className='errors'>{errors.banner_text}</span> : null
                                                }
                                                <label htmlFor='width'>Width</label>
                                                <input type="text" value={preview.banner_text}  onChange={onChangePreview} name='banner_text' placeholder='Banner title (optional)'></input>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {
                                    !checkbox  ? 
                                        <button type='button' onClick={handleOnClick}>Add</button>
                                        : null
                                }
                            
                            </div>
                        </div>

                        {doubled}                        
                        <button className='submitbtn' disabled={isButtonDisable} type='submit'>
                            {mode === 'create' ? 'Get URL' : 'Update preview'}
                        </button>
                        {
                            popupURL !== null ? <input onClick={(e) => copyBoard(e)} className='popupurl' value={popupURL}/> : null
                        }
                    </form>
                </div>
                <div className='preview__form-footer'>
                    ©NEXUS CREATIVE SOLUTIONS {anio} ALL RIGHTS RESERVED
                </div>

               
            </div>

            {
                openAlert.open ? 
                    <AlertSnackbar 
                    openAlert={openAlert.open} 
                    autoClose={openAlert.autoClose} 
                    message={openAlert.message} 
                    withProgress={openAlert.withProgress} 
                    closeAlert={setOpenAlert} 
                    /> : null
            } 
        </>
    )
}

export default FormPreview;
import React, {useEffect, useState, useContext , useRef} from "react";
import {useParams, useNavigate} from "react-router-dom"; 
import IframePreview from "../../components/IframePreview/IframePreview"
import { PreviewServices } from "../../services/preview.services";
import DarkModeContext from "../../contexts/DarkModeContext"
import "./ShowPreview.scss"
import logoxaxis from "./images/logoxaxis.png"
import logonexus from "../../components/images/nexuslogo.svg"
import eye from "./images/eye.png"
import creativestudio from "./images/creativestudio.png"
import SpeedDialMenu from "../../components/SpeedDialMenu/SpeedDialMenu";
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import xcs_logo from "../images/xcs_logo.svg"
import HeaderMobile from "../../components/Partials/HeaderMobile";
import FooterMobile from "../../components/Partials/FooterMobile";
  
const ShowPreview =() => {
    const switchButtonDarkMode = useRef(false)
    const [preview, setPreview] = useState({})
    const [titleHeader , setTitlteHeader] = useState('')
    const [isMobile , setIsMobile] = useState(false)
    let navigate = useNavigate();
    const {darkMode, setDarkMode} = useContext(DarkModeContext)
    const {id} = useParams()
    //console.log(useParams())

    useEffect(()=>{
        checkIfIsMobile()
        window.addEventListener("resize" , ()=>{
            checkIfIsMobile()
        });
    },[])

    useEffect(()=>{
        if( darkMode  && switchButtonDarkMode.current ){
            switchButtonDarkMode.current.checked = true;
        }
    },[switchButtonDarkMode.current])

    const checkIfIsMobile = () =>{
        if(window.screen.width <= 775){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    useEffect(()=>{
        PreviewServices(id)
        .then(data => {
            //console.log(data[0]);
            data[0].sizes.forEach(sizeShow => {
                sizeShow.show = true
            });    
            setPreview(data[0])
            setTitlteHeader(`- ${data[0].advertiser}`)
        })
        .catch(err =>  {console.log(err);
            navigate("/preview-maker", { replace: true })}

   )} , [])
   // console.log(preview);
    let mydate = new Date(preview.date);
    let month = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"][mydate.getMonth()];
    let str = month + " " + mydate.getDay() + ',' + ' ' + mydate.getFullYear();


    const dontShowIframe = (e, indexo) => {
        let showIframe = [ ...preview.sizes ];
        let stateShow = showIframe[ indexo ]['show']
        showIframe[ indexo ]['show'] = !stateShow
        setPreview({...preview, sizes: [...showIframe]})
        e.target.classList.toggle("hidden-iframe")
        console.log(e.target.style);

    }
    const changeDarkMode = () => {
        setDarkMode(!darkMode)
        localStorage.setItem('isDarkMode' , !darkMode);
    }

    const dateYear = new Date();
    const anio = dateYear.getFullYear();
    return(
        <>
            <Helmet>
                {/* Meta tags para Open Graph */}
                <meta property="og:title" content="Preview Builder" />
                <meta property="og:description" content="Preview creatives built by XCS" />
                <meta property="og:image" content={xcs_logo} />

                {/* Meta tags para Twitter Card */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Preview Builder" />
                <meta name="twitter:description" content="Preview creatives built by XCS" />

                <meta name="description" content="Preview creatives built by XCS" />
                <title>{ `Preview ${titleHeader}`}</title>
            </Helmet>
            <div className={darkMode ? "showpreview darkmode" : "showpreview"} >
                {
                    !isMobile ? <div className="showpreview__sidebar">
                                    <div>
                                        <div className='showpreview__sidebar-intro'>
                                            <img src={logonexus} alt='logo'/>   
                                        </div>
                                        <h1>Creatives Preview</h1>
                                        <div className="showpreview__sidebar-body">
                                            <div className="show-advertiser">
                                                <span>Advertiser</span>    
                                                {preview.advertiser}
                                            </div>
                                            <div className="market">
                                                <span>Market</span>
                                                {preview.market}
                                            </div>
                                            <div className="campaign">
                                                <span>Campaign</span>
                                                {preview.campaign}
                                            </div>
                                            <div className="format">
                                                <span>Format</span>
                                                {preview.format}
                                            </div>
                                            <div className="region">
                                                <span>Region</span>
                                                {preview.region}
                                            </div>
                                        </div>
                                        <div className="showpreview__sidebar-sizes">
                                            <p>Sizes </p>
                                            <div className="sizes">
                                                {
                                                    preview.sizes ? preview.sizes.map((size, index) => {
                                                        let copy = size.full_size ? 'Full size' : `${size.width}x${size.height}`
                                                        return <span onClick={(e) => {dontShowIframe(e, index) }} key={index}>{copy}</span>
                                                    }) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='showpreview__sidebar-footer'>
                                        <span>©NEXUS CREATIVE SOLUTIONS {anio} ALL RIGHTS RESERVED</span>
                                    </div>
                                </div> : <HeaderMobile preview={preview} />
                }
                
                <div className="showpreview__creatives">
                    <div className="darkmode">
                        <div className="c-toggle">
                            <label className="switch">
                                <input id="checkboxCreatives" ref={switchButtonDarkMode} type="checkbox" onChange={changeDarkMode} data-toggle="true"/>
                                <span className="slider round"></span>
                                <span className="text text-on">Dark Mode On</span>
                                <span className="text text-off">Dark Mode Off</span>
                            </label>
                        </div>
                    </div>
                    
                    <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' , height: 'auto'}}
                    >
                        {
                        preview.sizes ? preview.sizes.map((size, index) => <IframePreview key={index} info={size} check={size.full_size == 1 ? true : null} show={size.show} />) : null
                        } 
                    </div>
                </div>
            </div>

            { isMobile ? <FooterMobile /> : null }
                    {/* <SpeedDialMenu/> */}
        </>
    )
}

export default ShowPreview;